import AlbumSubtitle from './primitives/AlbumSubtitle';
import Art from './primitives/Art';
import Artist from './primitives/Artist';
import ArtistSubtitle from './primitives/ArtistSubtitle';
import Bullet from './primitives/Bullet';
import CatalogImageComponent from 'components/MediaServerImage/CatalogImage';
import CloseIcon from 'styles/icons/CloseIcon';
import DisabledWrapper from './primitives/DisabledWrapper';
import DragHandleIcon from './primitives/DragHandleIcon';
import DragHandlePrimitive from './primitives/DragHandle';
import ExplicitLyrics from 'components/ExplicitLyrics';
import ExplicitLyricsWrapper from './primitives/ExplicitLyricsWrapper';
import Hover from './primitives/Hover';
import Mask from './primitives/Mask';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonLink from 'components/Player/PlayButtonLink/PlayButtonLink';
import PlaylistRow from './primitives/PlaylistRow';
import RemoveTrack from './primitives/RemoveTrack';
import ShouldShow from 'components/ShouldShow';
import SongLinkContainer from './primitives/SongLinkContainer';
import SongTitle from './primitives/SongTitle';
import theme from 'styles/themes/default';
import Track from './primitives/Track';
import TrackActions from './primitives/TrackActions';
import TrackDuration from './primitives/TrackDuration';
import { Component } from 'react';
import { formatSongDuration } from 'utils/time/dates';
import { getAlbumUrl, getArtistUrl, getTrackUrl } from 'utils/url';
import { GrowlIcons } from 'components/Growls/constants';
import { Menu } from 'components/Tooltip';
import { SaveDeleteComponent } from 'modules/Analytics/helpers/saveDelete';
import { SortableHandle } from 'react-sortable-hoc';
import { STATION_TYPE } from 'constants/stationTypes';
import { THUMB_RES } from 'components/MediaServerImage';
import { TRIGGER_FROM_PLAYER_ROW } from 'state/Playlist/constants';
import { truncate } from 'utils/string';

const ArtistName = DisabledWrapper.withComponent('span');
const CatalogImage = DisabledWrapper.withComponent(CatalogImageComponent);

class SongRow extends Component {
  state = { isDragging: false };

  onSaveSong = () => {
    const { saveSongs, showNotifyGrowl, translate } = this.props;
    // TODO allow growl content as a param to saveTrack
    const tracks = this.getHandlerOpts().trackIds;

    saveSongs(tracks);

    showNotifyGrowl({
      icon: GrowlIcons.CheckCircle,
      title: translate('Song saved to Your Library'),
    });
  };

  onAddToPlaylist = () => {
    const { isAnonymous, openAddToPlaylist, openSignup, trackId, id } =
      this.props;
    if (isAnonymous) {
      openSignup('add_to_playlist');
    } else {
      openAddToPlaylist({
        component: SaveDeleteComponent.ListSongsOverflow,
        trackIds: [trackId || id],
        type: STATION_TYPE.TRACK,
      });
    }
  };

  onRemoveFromPlaylist = () => {
    const {
      editMode,
      onDelete,
      removeTracksFromPlaylist,
      showNotifyGrowl,
      translate,
      uuid: trackId,
    } = this.props;

    if (!editMode) {
      removeTracksFromPlaylist([trackId]);
    } else {
      onDelete(this.getHandlerOpts());
    }

    showNotifyGrowl({
      icon: GrowlIcons.Deleted,
      title: translate('Song removed from playlist'),
    });
  };

  getHandlerOpts() {
    const { id, playlist, uuid } = this.props;
    return {
      id,
      playlist,
      trackIds: [id],
      uuid,
    };
  }

  render() {
    const {
      albumId,
      albumName,
      allAccessPreview,
      artistName,
      artistId,
      canEdit,
      canPlay,
      canPlayPremiumPlaylist,
      currentlyPlaying,
      disabled,
      editMode,
      explicitLyrics,
      handleOnClick,
      id,
      id: trackId,
      internationalPlaylistRadioEnabled,
      isBackfill,
      isOD,
      isFreeUserMyPlaylistEnabled,
      isPremiumPlaylist,
      isTrialEligible,
      overflowEntitlements,
      ownerId,
      playedFrom,
      playlistId,
      playlistName,
      playingState,
      showUpsell,
      title,
      translate,
      uuid,
      showPlaylistButtons,
    } = this.props;
    const { isDragging } = this.state;
    const artistUrl = getArtistUrl(artistId, artistName);
    const albumUrl = getAlbumUrl(artistId, artistName, albumId, albumName);
    const trackUrl = getTrackUrl(artistId, artistName, trackId, title);
    const isPlaying =
      currentlyPlaying?.uniqueId && currentlyPlaying.uniqueId === uuid;

    let truncatedAlbum;

    if (albumName) {
      truncatedAlbum = albumName ? truncate(albumName, 350) : '';
    }

    const albumText =
      canPlay || isBackfill ? (
        <NavLink to={albumUrl}>{truncatedAlbum}</NavLink>
      ) : (
        <span>{truncatedAlbum}</span>
      );

    const explicitIcon = explicitLyrics ? (
      <ExplicitLyricsWrapper key="explicit">
        <ExplicitLyrics />
      </ExplicitLyricsWrapper>
    ) : null;

    const DragHandle = SortableHandle(() => (
      <DragHandlePrimitive
        data-test="song-row-drag-handle"
        onMouseDown={() => this.setState({ isDragging: true })}
        onMouseUp={() => this.setState({ isDragging: false })}
      >
        <DragHandleIcon />
      </DragHandlePrimitive>
    ));

    const isPlaylistEnabled =
      isFreeUserMyPlaylistEnabled || internationalPlaylistRadioEnabled;

    // Only show play button for PREMIUM playlists if the user type is PREMIUM [IHRWEB-17438]
    const showPlayButton =
      (isPremiumPlaylist && canPlayPremiumPlaylist) || !isPremiumPlaylist;

    return (
      <PlaylistRow
        data-test="item"
        data-test-state={isDragging ? 'draggable' : ''}
        isBackfill={isBackfill}
        isDraggable={editMode}
        isDragging={isDragging}
        playing={isPlaying}
      >
        {editMode && (
          <RemoveTrack onClick={this.onRemoveFromPlaylist} type="button">
            <CloseIcon
              css={{ marginTop: '.4rem' }}
              fill={theme.colors.white.primary}
              height="20"
              width="16"
            />
          </RemoveTrack>
        )}
        <ShouldShow shouldShow={canPlay}>
          <Art data-test="track-art">
            <NavLink
              classes={['relative', 'ui-on-dark']}
              title={title}
              to={internationalPlaylistRadioEnabled ? trackUrl : null}
            >
              <CatalogImage
                alt={title}
                aspectRatio={1}
                css={isBackfill ? { cursor: 'auto' } : {}}
                disabled={!canPlay && disabled}
                id={trackId}
                title={title}
                type="track"
                width={THUMB_RES}
              />
              <ShouldShow shouldShow={!internationalPlaylistRadioEnabled}>
                {showPlaylistButtons && showPlayButton ? (
                  <Hover isPlaying={isPlaying}>
                    <Mask />
                    <PlayButtonContainer
                      allAccessPreview={allAccessPreview}
                      artistId={artistId}
                      artistName={artistName}
                      classes={['play']}
                      currentlyPlaying={currentlyPlaying}
                      handleOnClick={handleOnClick}
                      isTrialEligible={isTrialEligible}
                      link={{
                        href: trackUrl,
                        text: title,
                      }}
                      playedFrom={playedFrom}
                      playedFromTrigger={TRIGGER_FROM_PLAYER_ROW}
                      playingState={playingState}
                      playlistId={playlistId}
                      playlistName={playlistName}
                      playlistUserId={ownerId}
                      stationType={STATION_TYPE.COLLECTION}
                      trackId={id}
                      trackName={title}
                      trackUuid={uuid}
                    />
                  </Hover>
                ) : null}
              </ShouldShow>
            </NavLink>
          </Art>
        </ShouldShow>
        <Track
          data-test="track-title"
          disabled={!canPlay && disabled}
          isBackfill={isBackfill}
          isDraggable={editMode}
          isPlaying={isPlaying}
        >
          <SongTitle isPlaying={isPlaying}>
            {(canPlay && showPlaylistButtons) || isBackfill ? (
              <SongLinkContainer>
                {((showUpsell || internationalPlaylistRadioEnabled) &&
                  playingState === 'PLAYING') ||
                isBackfill ? (
                  <>
                    <NavLink to={trackUrl}>{title}</NavLink>
                    {explicitIcon}
                  </>
                ) : (
                  <>
                    <PlayButtonContainer
                      allAccessPreview={allAccessPreview}
                      artistId={artistId}
                      artistName={artistName}
                      ButtonComponent={PlayButtonLink}
                      classes={['play']}
                      currentlyPlaying={currentlyPlaying}
                      isTrialEligible={isTrialEligible}
                      key="text-play-button"
                      link={{
                        href: trackUrl,
                        text: title,
                      }}
                      playedFrom={playedFrom}
                      playedFromTrigger={TRIGGER_FROM_PLAYER_ROW}
                      playingState={playingState}
                      playlistId={playlistId}
                      playlistName={playlistName}
                      playlistUserId={ownerId}
                      stationType={STATION_TYPE.COLLECTION}
                      trackId={id}
                      trackName={title}
                      trackUuid={uuid}
                    />
                    {explicitIcon}
                  </>
                )}
              </SongLinkContainer>
            ) : (
              <span css={{ display: 'block' }} key="disabled-track-name">
                {title}
              </span>
            )}
          </SongTitle>
          <ArtistSubtitle
            adjustSpacing={canPlay || isBackfill}
            key="artist-and-album"
          >
            {canPlay || isBackfill ? (
              <NavLink to={artistUrl}>{artistName}</NavLink>
            ) : (
              <span>{artistName}</span>
            )}
            <Bullet>&bull;</Bullet>
            {albumText}
          </ArtistSubtitle>
          <AlbumSubtitle
            adjustSpacing={canPlay || isBackfill}
            key="truncated-album"
          >
            {albumText}
          </AlbumSubtitle>
        </Track>
        <Artist data-test="track-artist" isDraggable={editMode}>
          {(canPlay || isBackfill) && !disabled ? (
            <NavLink to={artistUrl}>{artistName}</NavLink>
          ) : (
            <ArtistName disabled>{artistName}</ArtistName>
          )}
        </Artist>
        <TrackDuration
          data-test="track-duration"
          disabled={!canPlay && !isBackfill}
        >
          {formatSongDuration(this.props.duration)}
        </TrackDuration>
        <TrackActions data-test="track-actions">
          {editMode ? (
            <DragHandle />
          ) : (
            <Menu>
              <Menu.List>
                {overflowEntitlements.showAdd && isOD && isPlaylistEnabled && (
                  <Menu.Item>
                    <NavLink
                      onClick={this.onAddToPlaylist}
                      title={translate('Add to Playlist')}
                    >
                      {translate('Add to Playlist')}
                    </NavLink>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <NavLink title={translate('Go to Artist')} to={artistUrl}>
                    {translate('Go to Artist')}
                  </NavLink>
                </Menu.Item>
                {isOD && (
                  <Menu.Item>
                    <NavLink title={translate('Go to Album')} to={albumUrl}>
                      {translate('Go to Album')}
                    </NavLink>
                  </Menu.Item>
                )}
                {canEdit && (
                  <Menu.Item>
                    <NavLink
                      onClick={this.onRemoveFromPlaylist}
                      title={translate('Remove from Playlist')}
                    >
                      {translate('Remove from Playlist')}
                    </NavLink>
                  </Menu.Item>
                )}
              </Menu.List>
            </Menu>
          )}
        </TrackActions>
      </PlaylistRow>
    );
  }
}

export default SongRow;
