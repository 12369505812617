import checkAutoplay from 'web-player/autoplay';
import colors from 'styles/colors';
import Head from '../PlaylistProfileHead';
import Hero from './Hero';
import localStorage from 'utils/localStorage';
import LocalStorageKeys from 'constants/localStorageKeys';
import NotificationBanner from 'components/Banners/NotificationBanner';
import PageBody, { ViewName } from 'views/PageBody';
import Songs from './Songs';
import trackers from 'trackers';
import { buildSocialDescription, getAssetImgUrl } from './helpers';
import { Component } from 'react';
import { COUNTRIES } from 'state/Config/constants';
import { encodePlaylistSeedId } from 'state/Playlist/helpers';
import { Events } from 'modules/Analytics';
import { Navigate } from 'state/Routing/types';
import { REQUEST_STATE } from 'state/Playlist/constants';
import { RequestState } from 'state/Playlist/types';
import { STATION_TYPE, StationTypeValue } from 'constants/stationTypes';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';
import type { PlaylistType } from './types';
import type { Track } from 'state/Tracks/types';

type Props = {
  adsFree: boolean;
  backfillTracks?: Array<number> | Array<Track> | undefined;
  canPlay: boolean;
  canPlayPremiumPlaylist: boolean;
  currentPath: string;
  description: string;
  canEditPlayableAsRadio: boolean;
  isAllAccessFreePreview: boolean;
  isFreeMyPlaylistEnabled: boolean;
  isFreeUserPlaylistCreationEnabled: boolean;
  isPremiumPlaylist: boolean;
  imgUrl: string;
  mediaServerUrl: string;
  name: string;
  navigate: Navigate;
  ownerId: string;
  playlistAdsEnabled: boolean;
  playlistId: string;
  profileId: number;
  requestBackfillTracks: (ids: Array<number>) => Promise<any>;
  requestPlaylist: (playlist: {
    playlistId: string;
    playlistUserId: string;
  }) => Promise<any>;
  requestState: RequestState;
  requestUserPlaylists: () => void;
  seedId: string;
  siteUrl: string;
  slugIdAndOwner: {
    id: string;
    owner: string;
  };
  stationLoaded: boolean;
  stationType: StationTypeValue;
  subscriptionType: keyof typeof SUBSCRIPTION_TYPE;
  tracks: Array<Track>;
  translate: IGetTranslateFunctionResponse;
  type: PlaylistType;
  upgradeLink: string;
  userCountry: string;
};

type State = {
  hasDismissedBanner: boolean;
};

export default class PlaylistProfile extends Component<Props, State> {
  static defaultProps = {
    tracks: [],
  };

  state = { hasDismissedBanner: true };

  componentDidMount() {
    const {
      seedId,
      stationLoaded,
      stationType,
      profileId,
      requestUserPlaylists,
      requestState,
      requestPlaylist,
      ownerId,
      playlistId,
      navigate,
      canPlay,
    } = this.props;

    if (
      requestState === REQUEST_STATE.NEEDS_AUTH ||
      requestState === REQUEST_STATE.NOT_REQUESTED
    )
      requestPlaylist({ playlistId, playlistUserId: ownerId });

    if (requestState === REQUEST_STATE.FAILED) navigate({ path: '/404' });

    if (profileId) requestUserPlaylists();
    // WEB-13647 - 5/10/19 - AV
    // before checking autoplay make sure that
    // 1) stationLoaded: the player has loaded
    // 2) canPlay: we have enough information to know that the current playlist is playable
    //    (and it is)
    // 3) we have a seedId for the playlist (clientside nav can sometimes cause this to be briefly
    //    undefined)
    if (stationLoaded && canPlay && seedId) {
      checkAutoplay({ seedId, seedType: stationType });
    }
    this.setState({
      hasDismissedBanner: Boolean(
        localStorage.getItem(LocalStorageKeys.DismissedMyPlaylistBanner, false),
      ),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      canPlay,
      profileId,
      seedId,
      stationLoaded,
      requestUserPlaylists,
      stationType,
      requestState,
      navigate,
      requestPlaylist,
      playlistId,
      ownerId,
    } = nextProps;
    if (
      requestState === REQUEST_STATE.NEEDS_AUTH ||
      requestState === REQUEST_STATE.NOT_REQUESTED
    )
      requestPlaylist({ playlistId, playlistUserId: ownerId });

    if (requestState === REQUEST_STATE.FAILED) navigate({ path: '/404' });

    // WEB-13647 - 5/10/19 - AV
    // before checking autoplay make sure that
    // 1) stationLoaded: the player has loaded
    // 2) canPlay: we have enough information to know that the current playlist is playable
    //    (and it is)
    // 3) we have a seedId for the playlist (clientside nav can sometimes cause this to be briefly
    //    undefined)
    // we only want this to happen once so unlike in componentDidMount, we need to confirm that a
    // change occured.
    if (
      canPlay &&
      stationLoaded &&
      seedId &&
      (canPlay !== this.props.canPlay ||
        stationLoaded !== this.props.stationLoaded ||
        seedId !== this.props.seedId)
    ) {
      checkAutoplay({ seedId, seedType: stationType });
    }

    if (profileId && profileId !== this.props.profileId) {
      requestPlaylist({ playlistId, playlistUserId: ownerId });
      requestUserPlaylists();
    }
  }

  getShareConfig = () => {
    const {
      slugIdAndOwner,
      translate,
      currentPath,
      name,
      description,
      mediaServerUrl,
      siteUrl,
      stationType,
      tracks,
      imgUrl,
    } = this.props;
    const { id: playlistId, owner: playlistUserId } = slugIdAndOwner;
    trackers.track(Events.Share, {
      seedType: stationType,
      seedId: playlistId,
      stationName: name,
    });

    return {
      seedType: STATION_TYPE.COLLECTION, // TODO: Should this be conditional with STATION_TYPES.PLAYLIST_RADIO? [DEM 08/08/2021]
      seedId: encodePlaylistSeedId(playlistUserId, playlistId),
      url: currentPath,
      model: {
        description: buildSocialDescription({ description, translate }),
        imgUrl: getAssetImgUrl({
          imgUrl,
          mediaServerUrl,
          siteUrl,
          tracksLength: tracks.length,
        }),
        name,
        playlist: {
          id: playlistId,
          userId: playlistUserId,
        },
      },
      stationName: name,
      dimensions: WIDGET_DIMENSIONS.PLAYLIST,
    };
  };

  render() {
    const {
      adsFree,
      backfillTracks,
      canEditPlayableAsRadio,
      canPlayPremiumPlaylist,
      isAllAccessFreePreview,
      isFreeMyPlaylistEnabled,
      isFreeUserPlaylistCreationEnabled,
      isPremiumPlaylist,
      name,
      ownerId,
      playlistAdsEnabled,
      profileId,
      requestState,
      slugIdAndOwner,
      subscriptionType,
      tracks,
      type,
      upgradeLink,
      userCountry,
    } = this.props;
    const { id: playlistId } = slugIdAndOwner;
    const noAds = adsFree || !playlistAdsEnabled;
    const { translate } = this.props;

    const isFreePlaylistEnabled =
      isFreeMyPlaylistEnabled || isFreeUserPlaylistCreationEnabled;
    const isFreePlaylistUser = isFreePlaylistEnabled && canEditPlayableAsRadio;
    const isAuOrNzUser =
      userCountry === COUNTRIES.AU || userCountry === COUNTRIES.NZ;
    const userCreatedPlaylist = ownerId === String(profileId);
    const hasNotDismissedBanner = !this.state.hasDismissedBanner;

    const shouldShowBanner =
      (hasNotDismissedBanner &&
        userCreatedPlaylist &&
        subscriptionType === SUBSCRIPTION_TYPE.FREE &&
        type !== 'new4u' &&
        isFreePlaylistUser) ||
      (isAuOrNzUser && hasNotDismissedBanner && userCreatedPlaylist);

    const notificationBannerMessage = translate(
      'Your songs shuffled with songs we think you’ll like. The more songs you add, the better our picks get.',
    );
    const notificationBannerTitle = translate(
      'Introducing a new playlist experience',
    );

    const onClose = () => {
      this.setState({ hasDismissedBanner: true });
      localStorage.setItem(LocalStorageKeys.DismissedMyPlaylistBanner, 'true');
    };

    const welcomeBanner = shouldShowBanner ? (
      <NotificationBanner
        message={notificationBannerMessage}
        onClose={onClose}
        showClose
        title={notificationBannerTitle}
      />
    ) : null;

    // Only show upgrade banner if playlist is premium and user is anon/free, and user is in US [IHRWEB-17438]
    const shouldShowUpgradeBanner =
      isPremiumPlaylist &&
      !canPlayPremiumPlaylist &&
      userCountry === COUNTRIES.US &&
      !isAllAccessFreePreview;

    const notificationUpgradeBannerMessage = translate(
      'Upgrade for this playlist',
    );
    const notificationUpgradeBannerButton = {
      link: upgradeLink,
      text: translate('Learn More'),
      filled: true,
    };

    const upgradeBanner = shouldShowUpgradeBanner ? (
      <NotificationBanner
        button={notificationUpgradeBannerButton}
        message={notificationUpgradeBannerMessage}
        newWindow={false}
      />
    ) : null;

    const andMoreText =
      subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM ? (
        <span
          css={{
            display: 'flex',
            color: colors.gray['500'],
            justifyContent: 'center',
          }}
        >
          and more...
        </span>
      ) : null;

    if (REQUEST_STATE.FAILED === requestState) return <Head />;

    return (
      <>
        <Head />
        <Hero />
        <PageBody
          banner={welcomeBanner}
          dataTest={ViewName.PlaylistProfile}
          hasHero
          noAds={noAds}
          premiumBanner={upgradeBanner}
        >
          <Songs
            backfillTracks={backfillTracks}
            getShareConfig={this.getShareConfig}
            isFreePlaylistEnabled={isFreePlaylistEnabled}
            isFreePlaylistUser={isFreePlaylistUser}
            name={name}
            ownerId={ownerId}
            playlistId={playlistId}
            subscriptionType={subscriptionType}
            tracks={tracks}
          />
          {andMoreText}
        </PageBody>
      </>
    );
  }
}
