import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  isEditing: boolean;
};

const Container = styled('div')<Props>(({ isEditing = false, theme }) => ({
  width: '50%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: '100%',
  },

  ...(isEditing
    ? {
        height: 'auto',
        marginTop: '-2rem',
        width: '100%',

        [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
          marginTop: '-1.2rem',
        },
      }
    : {}),
}));

export default Container;
