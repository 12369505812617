import styled from '@emotion/styled';

type Props = {
  isBackfill?: boolean;
  isDragging: boolean;
};

const PlaylistRow = styled('div')<Props>(
  ({ isBackfill = false, isDragging, theme }) => ({
    background: isDragging ? theme.colors.white.primary : 'auto',
    border: isDragging ? 'none' : 'auto',
    borderCollapse: 'collapse',
    borderTop: `solid 0.1rem ${theme.colors.gray['300']}`,
    boxShadow: isDragging
      ? `0 0 1.4rem -0.1rem ${theme.colors.transparent.dark}`
      : 'none',
    cursor: isDragging ? 'move' : 'auto',
    height: isBackfill ? '6rem' : '8rem',
    lineHeight: '3rem',
    position: 'relative',
    width: '100%',
    zIndex: isDragging ? 119 : 1,
  }),
);

export default PlaylistRow;
