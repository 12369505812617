import analytics from 'modules/Analytics';
import Dots from 'styles/icons/Dots';
import FilledButton from 'primitives/Buttons/FilledButton';
import NoSongs from 'primitives/NoSongs';
import NoSongsMessage from 'primitives/NoSongsMessage';
import Playlists from 'styles/icons/PlaylistsLarge';
import SaveIcon from 'styles/icons/player/Controls/Save/SaveIcon';
import theme from 'styles/themes/default';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ConnectedModals } from 'state/UI/constants';
import { openModal } from 'state/UI/actions';
import { useDispatch } from 'react-redux';

type Props = {
  isDefaultPlaylist: boolean;
};

function EmptySongsList({ isDefaultPlaylist }: Props) {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const openSearch = () => {
    if (isDefaultPlaylist) {
      analytics.trackClick!('my_free_playlist|body|add_song');
    }
    dispatch(openModal({ id: ConnectedModals.Search, context: null }));
  };

  return (
    <NoSongs>
      <Playlists />
      <NoSongsMessage>
        {isDefaultPlaylist ? (
          <>
            <div>{translate('No added songs yet')}</div>
            <div>
              {translate('Tap ')}{' '}
              <SaveIcon styles={{ position: 'relative', top: '12px' }} />
              <span
                style={{
                  float: 'left',
                  marginLeft: '-999px',
                  visibility: 'hidden',
                }}
              >
                {translate('Save')}
              </span>
              {translate(' on the player to add a song to My Playlist')}
            </div>
          </>
        ) : (
          <>
            <div>
              {translate('Click on the')}
              <Dots
                css={{ margin: '0 1rem' }}
                fill={theme.colors.black.primary}
              />
              {translate('button next to a song')}
            </div>
            <div>{translate('and select Add to Playlist.')}</div>
          </>
        )}
      </NoSongsMessage>
      <FilledButton onClick={openSearch} styleType="dark">
        {translate('Find Music')}
      </FilledButton>
    </NoSongs>
  );
}

export default EmptySongsList;
