import EmptySongsList from 'components/EmptySongsList/EmptySongsList';
import FilledButton from 'primitives/Buttons/FilledButton';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/InfiniteScroll/Loader';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlaylistSongsRow from 'views/Playlist/PlaylistProfile/SongRow';
import PlaylistTypes from 'constants/playlistTypes';
import SortablePlaylist from 'views/Playlist/PlaylistProfile/SortablePlaylist';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';

import {
  Artist,
  Duration,
  EditConfirm,
  Form,
  PlaylistHeading,
  PlaylistList,
  PlaylistListContainer,
  Title,
} from './primitives';
import {
  FunctionComponent,
  MouseEvent,
  SyntheticEvent,
  TouchEvent,
  useCallback,
  useState,
} from 'react';
import {
  getCurrentAllowedNumber,
  getCurrentOwnerId,
  getCurrentPlaylistId,
  getCurrentRequestState,
  getCurrentStationType,
  getCurrentTrackIds,
  getCurrentType,
} from 'state/Playlist/selectors';
import { getIsMobile } from 'state/Environment/selectors';
import { REQUEST_STATE } from 'state/Playlist/constants';
import { requestTracks } from 'state/Tracks/actions';
import { showPlaylistUpsellSelector } from 'state/Entitlements/selectors';
import { STATION_TYPE } from 'constants/stationTypes';
import { useDispatch, useSelector } from 'react-redux';
import type { Track } from 'state/Tracks/types';

function stopPropogation(
  e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>,
) {
  e.preventDefault();
  return false;
}

type Props = {
  cancelEditing: (e?: SyntheticEvent<HTMLButtonElement>) => void;
  canEdit: boolean;
  canPlay: boolean;
  doneEditing: (event: SyntheticEvent<HTMLButtonElement>) => void;
  isMine: boolean;
  onDelete: ({ uuid }: { uuid: string }) => void;
  onMove: (a: { newIndex: number; oldIndex: number }) => void;
  overflowEntitlements: Record<string, any>;
  playedFrom: string;
  playlistName: string;
  reorderActive: boolean;
  tracks: Array<Track>;
};

const Songslist: FunctionComponent<Props> = ({
  cancelEditing,
  canEdit,
  canPlay,
  doneEditing,
  isMine,
  onDelete,
  onMove,
  overflowEntitlements,
  playedFrom,
  playlistName,
  reorderActive,
  tracks,
}) => {
  const allowed = useSelector(getCurrentAllowedNumber);
  const dispatch = useDispatch();
  const showPlaylistUpsell = useSelector(showPlaylistUpsellSelector);
  const isMobile = useSelector(getIsMobile);
  const ownerId = useSelector(getCurrentOwnerId);
  const playlistId = useSelector(getCurrentPlaylistId);
  const requestState = useSelector(getCurrentRequestState);
  const stationType = useSelector(getCurrentStationType);
  const trackIds = useSelector(getCurrentTrackIds);
  const type = useSelector(getCurrentType);
  const translate = useTranslate();

  const [isFetching, setIsFetching] = useState(false);
  const [trackCount, setTrackCount] = useState(tracks?.length ?? 0);

  useMount(() => cancelEditing);

  const loadMoreTracks = useCallback(async () => {
    if (trackCount >= trackIds.length) return;

    const newTrackCount = trackCount + 10;
    const ids = trackIds
      .slice(trackCount, newTrackCount)
      .map(track => track.trackId);

    if (ids.length) {
      setIsFetching(true);
      await dispatch(requestTracks(ids));
    }

    setIsFetching(false);
    setTrackCount(newTrackCount);
  }, [dispatch, trackCount, trackIds]);

  const isPlaylistRadio = stationType === STATION_TYPE.PLAYLIST_RADIO;

  const isDefaultPlaylist = type === PlaylistTypes.Default;
  const showEmptySongsList =
    (type === PlaylistTypes.Default || type === PlaylistTypes.User) &&
    requestState === REQUEST_STATE.FETCHED;
  const NoTracksData = showEmptySongsList ? (
    <EmptySongsList isDefaultPlaylist={isDefaultPlaylist} />
  ) : (
    <Loader />
  );

  return (
    <>
      {tracks.length ? (
        <>
          <PlaylistHeading>
            <Title>
              {translate('SONG')} / {translate('ALBUM TITLE')}
            </Title>
            <Artist>{translate('ARTIST')}</Artist>
            <Duration>{translate('DURATION')}</Duration>
          </PlaylistHeading>
          <PlaylistListContainer
            // prevents text selection while dragging in firefox, which can screw up reordering
            {...(reorderActive && !isMobile
              ? {
                  onMouseDown: stopPropogation,
                  onMouseMove: stopPropogation,
                  onTouchMove: stopPropogation,
                  onTouchStart: stopPropogation,
                }
              : {})}
            key="contents"
          >
            <PlaylistList>
              <SortablePlaylist
                hideSortableGhost
                lockAxis="y"
                lockToContainerEdges
                onSortEnd={onMove}
                transitionDuration={200}
                useWindowAsScrollContainer
              >
                <InfiniteScroll
                  hasMore={trackCount < trackIds.length}
                  isFetching={isFetching}
                  loadMore={loadMoreTracks}
                  offsetBottom={20}
                  overscanRowCount={5}
                  rowHeight={75}
                  threshold={5}
                >
                  {tracks.map((track, index) => {
                    const {
                      artistId,
                      artistName,
                      isOnDemandTrack,
                      explicitLyrics,
                      uuid,
                    } = track;

                    // if playlistRadio, then allow tracks to be enabled
                    // if 'allowed' exists, limit enabled tracks to that number, otherwise they are all available
                    const isAllowed =
                      isPlaylistRadio ||
                      (allowed && allowed > index) ||
                      !showPlaylistUpsell;
                    const trackEnabled = isOnDemandTrack && isAllowed;

                    // Decided to 'filter' here because I'm unsure what doing the filtering in the selector
                    // may break. If there's no title, then don't render the row
                    return track?.title?.length ? (
                      <PlaylistSongsRow
                        {...track}
                        artistId={artistId}
                        artistName={artistName}
                        canEdit={canEdit}
                        canPlay={trackEnabled || canPlay}
                        disabled={!reorderActive || !canEdit}
                        editMode={reorderActive}
                        explicitLyrics={explicitLyrics}
                        index={index}
                        isMine={isMine}
                        isOD={isOnDemandTrack}
                        key={uuid}
                        onDelete={onDelete}
                        overflowEntitlements={overflowEntitlements}
                        ownerId={ownerId}
                        playedFrom={playedFrom}
                        playlistId={playlistId}
                        playlistName={playlistName}
                        seedType={STATION_TYPE.TRACK}
                        showUpsell={showPlaylistUpsell}
                        stationType={stationType}
                        subKey={`PlaylistRowSortable-${track.id}-${track.trackId}`}
                        tracksLength={tracks.length}
                      />
                    ) : null;
                  })}
                </InfiniteScroll>
              </SortablePlaylist>
            </PlaylistList>
          </PlaylistListContainer>
        </>
      ) : (
        NoTracksData
      )}
      {canEdit && (
        <EditConfirm isEditing={reorderActive}>
          <Form method="GET" onSubmit={() => false}>
            <OutlinedButton
              marginRight="2rem"
              marginTop="2rem"
              onClick={cancelEditing}
              tabIndex={1}
            >
              {translate('Cancel')}
            </OutlinedButton>
            <FilledButton
              data-test="confirm-button"
              marginTop="2rem"
              onClick={doneEditing}
              styleType="cta"
              tabIndex={2}
            >
              {translate('Done')}
            </FilledButton>
          </Form>
        </EditConfirm>
      )}
    </>
  );
};

export default Songslist;
